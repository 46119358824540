import React, { Component } from 'react';

// import Icon from '../icons/Icons';

const MSG_PROPS = new Map([
    ['loadError', { className: 'bg-danger', icon: 'danger' }],
    ['error', { className: 'bg-danger', icon: 'danger' }],
    ['success', { className: 'bg-success', icon: 'check' }],
]);
export default class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            animate: false,
        };
        this.timeout = null;
    }

    componentDidMount() {
        this.timeout = setTimeout((_) => this.remove(), 5000);
        this.setState({
            animate: true,
        });
    }

    remove(e) {
        this.setState({
            animate: false,
        });
        setTimeout((_) => this.props.remove(this.state.id), 300);
        e && e.stopPropagation();
        e && e.nativeEvent.stopImmediatePropagation();
        clearTimeout(this.timeout);
    }

    render() {
        const { text } = this.props;
        const type = this.props.type || 'error';
        const msgProps = MSG_PROPS.get(type);
        return (
            <div className="w-100 transition" style={{ opacity: this.state.animate ? 1 : 0 }}>
                <div
                    className={`type ${msgProps.className} text-white text-center p-3 rounded mt-3 mb-0 alert mx-auto`}
                    style={{ width: 500 }}
                >
                    <button className="close text-white" onClick={(e) => this.remove(e)}>
                        &times;
                    </button>
                    <h5 className="text-uppercase">{/*{msgProps.icon && (<Icon icon={msgProps.icon}/>)}*/}</h5>
                    <p className="mb-0">{text}</p>
                </div>
            </div>
        );
    }
}
