import ServerConnector from './Serverconnector';
import customerTypes from '../configs/customerTypes';

class CustomersService extends ServerConnector {
    constructor(path = 'api/customers') {
        super(path);
    }

    /**
     * Add Customer
     * @param data
     * @returns {Promise<unknown>}
     */
    addCustomer(data) {
        //Set default parameters for both customer types
        let modifiedData = {
            name: data.name,
            accountType: data.accountType,
            type: data.type,
        };
        // In case if the customer is Bynder one, add account ID, portal URL and brand ID
        if (data.type === customerTypes.BYNDER) {
            modifiedData = {
                ...modifiedData,
                bynderAccountId: data.bynderAccountId || null,
                bynderPortalUrl: data.bynderPortalUrl || null,
                bynderBrandId: data.bynderBrandId || null,
            };
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(modifiedData),
        };
        return this.send({ path: '/', options });
    }

    /**
     * Delete
     * @param id
     * @returns {Promise<unknown>}
     */
    deleteCustomer(id) {
        const options = {
            method: 'DELETE',
        };
        return this.send({ path: `/${id}`, options });
    }

    /**
     * Customer Update while modifying the data for Bynder adding brand id
     * @param data
     * @returns {Promise<unknown>}
     */
    editCustomer(data) {
        let modifiedData = {
            name: data.name,
            accountType: data.accountType,
            type: data.type,
        };
        if (data.type === customerTypes.BYNDER) {
            modifiedData.bynderBrandId = data.bynderBrandId || null;
        }

        const options = {
            method: 'PUT',
            body: JSON.stringify(modifiedData),
        };
        return this.send({ path: `/${data.id}`, options });
    }

    /**
     *
     * @param type
     * @param page
     * @returns {Promise<unknown>}
     */
    getCustomers(type, search = '', page = null) {
        const options = { method: 'GET' };

        let query = `duplicationStatusIncluded=true&type=${type}&query=${search}`;
        if (page !== null) query += `&page=${page}`;

        return this.send({ path: `/?${query}`, options });
    }

    /**
     *
     * @param data
     * @param type
     * @returns {Promise<unknown>}
     */
    duplicateCustomer(data, type) {
        //Add type and originCustomerID (customer, from account of which we are making the request)
        data.type = type;
        data.originCustomerId = data.id;
        // we don't need the data.id anymore
        delete data.id;

        const options = {
            method: 'POST',
            body: JSON.stringify(data),
        };

        return this.send({ path: '/duplicate', options });
    }

    /**
     * Settings update
     * @param id
     * @returns {Promise<unknown>}
     */
    getCustomerSettings(id) {
        const options = {
            method: 'GET',
        };
        return this.send({ path: `/${id}/settings`, options });
    }

    /**
     *
     * @param id
     * @param data
     * @returns {Promise<unknown>}
     */
    setCustomerSettings(id, data) {
        const options = {
            method: 'PUT',
            body: JSON.stringify(data),
        };
        return this.send({ path: `/${id}/settings`, options });
    }

    /**
     *
     * @param id
     * @returns {Promise<unknown>}
     */
    getCustomerRenditionProfiles(id) {
        const options = {
            method: 'GET',
        };
        return this.send({ path: `/${id}/rendition-profiles`, options });
    }
}

export default new CustomersService();
