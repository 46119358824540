import ServerConnector from './Serverconnector';

class FontsService extends ServerConnector {
    constructor(path = 'api/fonts') {
        super(path);
    }

    getFontFamiliesByType(type, query, page = 0) {
        const options = { method: 'GET' };
        const searchQuery = query ? `&query=${query}` : '';

        return this.send({ path: `/fontFamily/byType/${type}?page=${page}${searchQuery}`, options });
    }

    getFontFamily(fontFamilyId) {
        const options = {
            method: 'GET',
        };
        return this.send({ path: `/fontFamily/${fontFamilyId}`, options });
    }

    addFontFamily(data) {
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
        };

        return this.send({ path: `/fontFamily`, options });
    }

    editFontFamily(data) {
        const options = {
            method: 'PUT',
            body: JSON.stringify(data),
        };
        return this.send({ path: `/fontFamily/${data.id}`, options });
    }

    deleteFontFamily(fontFamilyId) {
        const options = {
            method: 'DELETE',
        };
        return this.send({ path: `/fontFamily/${fontFamilyId}`, options });
    }

    //---------------------------------------

    addFontFile(fontFamilyId, data) {
        const options = {
            method: 'POST',
            body: data,
        };

        return this.send(
            {
                path: `/fontFile/${fontFamilyId}`,
                options,
            },
            false,
        );
    }

    updateFontFile(data) {
        const options = {
            method: 'PUT',
            body: JSON.stringify(data),
        };

        return this.send({
            path: `/fontFile/${data.id}`,
            options,
        });
    }
}

export default new FontsService();
