import React, { Component, PureComponent } from 'react';
import RenditionsService from '../../services/RenditionsService';

class ShowMore extends PureComponent {
    constructor() {
        super();

        this.state = {
            showAll: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            showAll: !this.state.showAll,
        });
    }

    render() {
        const { children } = this.props;
        const { showAll } = this.state;
        return (
            <div
                style={{ maxWidth: showAll ? 'none' : 200 }}
                className={showAll ? '' : 'text-truncate'}
                onClick={this.toggle}
            >
                {children ? children.toString() : ''}
            </div>
        );
    }
}

export default class Renditions extends Component {
    constructor() {
        super();

        this.state = {
            renditions: [],
            keys: [],
        };
    }

    componentDidMount() {
        RenditionsService.getRenditions().then(({ json, status }) => {
            this.setState({
                renditions: json.items,
            });
        });
    }

    render() {
        const { renditions } = this.state;
        return (
            <div>
                <div className="form-row py-3">
                    <div className="col-auto">
                        <h4 className="display-5 m-0">Renditions</h4>
                    </div>
                </div>
                <div className="accordion" id="accordion">
                    {renditions.map((rendition) => {
                        const keys = Object.keys(rendition.configurations.find((c) => c.type === 'VIDEO') || {});
                        return (
                            <div className="card" key={rendition.id}>
                                <div className="card-header">
                                    <h2 className="mb-0">
                                        <button
                                            className="btn btn-link"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={`#collapse${rendition.id}`}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            {rendition.name}
                                        </button>
                                    </h2>
                                </div>
                                <div
                                    id={`collapse${rendition.id}`}
                                    className={`collapse ${rendition.id === +this.props.match.params.id ? 'show' : ''}`}
                                    data-parent="#accordion"
                                >
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-striped mb-0">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        {keys.map((key) => (
                                                            <th key={key}>{key}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rendition.configurations.map((configuration) => (
                                                        <tr key={configuration.id}>
                                                            {keys.map((key) => (
                                                                <td key={key}>
                                                                    <ShowMore>{configuration[key]}</ShowMore>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
