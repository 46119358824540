import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import customersService from './../../services/CustomersService';
import AdminTable from './../../common/AdminTable/AdminTable';
import NavLink from 'react-router-dom/es/NavLink';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import permissions from '../../configs/permissions';
import { requiredValidator, uuidValidator, urlValidator, brandIdValidator } from '../../helpers/validators';
import { accountTypeOptions } from '../../configs/accountTypes';
import DuplicateCustomerModal from './DuplicateCustomerModal';
import jq from 'jquery';
import customerDuplicationStatuses, {
    customerDuplicationStatusLabels,
} from '../../configs/customerDuplicationStatuses';
import { showFailToLoadMessage } from '../../store/messages/messages.actions';

const nameRequiredValidator = requiredValidator('Name is required');
const bynderAccountIdValidator = uuidValidator('Bynder account id should be a valid uuid');
const brandIDValidator = brandIdValidator(
    'Brand ID should be in the following format: XXXXXXXX-XXXX-XXXX-XXXXXXXXXXXXXXXX',
);
const portalUrlValidator = urlValidator('Portal url should be a valid url');

class CustomersBynder extends Component {
    state = {
        customerToDuplicate: null,
    };

    constructor(props) {
        super(props);
        this.texts = {
            addText: 'Add customer',
            editTitle: 'Edit customer',
        };
    }

    openDuplicateCustomerModal = (customer) => {
        this.setState({ customerToDuplicate: customer }, () => {
            jq('#duplicateCustomerModal').modal('show');
        });
    };

    render() {
        const { customerToDuplicate } = this.state;

        const cols = [
            {
                title: 'Name',
                name: 'name',
                validator: nameRequiredValidator,
            },
            {
                title: 'Bynder account id',
                name: 'bynderAccountId',
                validator: bynderAccountIdValidator,
                ignoreInEdit: true,
            },
            {
                title: 'Bynder portal url',
                name: 'bynderPortalUrl',
                validator: portalUrlValidator,
                ignoreInEdit: true,
            },
            {
                title: 'Brand ID',
                name: 'bynderBrandId',
                validator: brandIDValidator,
            },
            {
                title: 'Account type',
                name: 'accountType',
                ignoreInEdit: true,
                display: (val) => {
                    const selectAccountType = accountTypeOptions.find((at) => at.value === val);
                    return selectAccountType.label || val;
                },
                type: 'select',
                options: accountTypeOptions,
            },
            // {
            //     title: 'Settings',
            //     name: 'id',
            //     ignoreInAdd: true,
            //     ignoreInEdit: true,
            //     display: (id) => <NavLink to={`/customers/${id}`}>settings</NavLink>,
            // },
        ];

        if (AuthorizationHelper.hasPermission(permissions.RENDITION_PROFILES.READ)) {
            cols.push({
                title: 'Renditions',
                name: 'renditions',
                ignoreInAdd: true,
                ignoreInEdit: true,
                display: (renditions) =>
                    renditions.map((rendition) => (
                        <NavLink key={rendition.id} className="badge badge-primary" to={`/renditions/${rendition.id}`}>
                            {rendition.name}
                        </NavLink>
                    )),
            });
        }

        // if (AuthorizationHelper.hasPermissions([permissions.CUSTOMERS.WRITE])) {
        //     cols.push({
        //         title: '',
        //         name: 'duplicate',
        //         ignoreInAdd: true,
        //         ignoreInEdit: true,
        //         display: (_, customer) =>
        //             ((!customer.duplicationRequestStatus ||
        //                 customer.duplicationRequestStatus === customerDuplicationStatuses.DUPLICATED) && (
        //                 <button
        //                     className="btn btn-primary btn-sm"
        //                     onClick={() => this.openDuplicateCustomerModal(customer)}
        //                 >
        //                     Duplicate
        //                 </button>
        //             )) ||
        //             customerDuplicationStatusLabels[customer.duplicationRequestStatus],
        //     });
        // }

        return (
            <Fragment>
                <AdminTable
                    ref={(el) => (this.table = el)}
                    hideAdd={!AuthorizationHelper.hasPermissions([permissions.CUSTOMERS.WRITE])}
                    editType="modal"
                    bynderCustomer={true}
                    hideDelete={!AuthorizationHelper.hasPermissions([permissions.CUSTOMERS.DELETE])}
                    texts={this.texts}
                    edit={(d, oldEntity) => customersService.editCustomer({ ...oldEntity, ...d, type: 'BYNDER' })}
                    add={(d) => customersService.addCustomer({ ...d, type: 'BYNDER' })}
                    delete={(id) => customersService.deleteCustomer(id)}
                    fetchData={(page) => customersService.getCustomers('BYNDER', this.props.search, page)}
                    cols={cols}
                    search={this.props.search}
                />

                <DuplicateCustomerModal
                    onClose={() => this.setState({ customerToDuplicate: null })}
                    customer={customerToDuplicate}
                    onDuplicateRequest={(values) =>
                        customersService
                            .duplicateCustomer(values, 'BYNDER')
                            .then(({ status, json }) => {
                                if (status !== 200) {
                                    this.props.showFailToLoadMessage(json.msg);
                                }
                            })
                            .finally(() => this.table.getAndSetData())
                    }
                />
            </Fragment>
        );
    }
}

function mapProperties(state) {
    return {};
}

function mapActions(dispatch) {
    return {
        showFailToLoadMessage: (msg) => dispatch(showFailToLoadMessage(msg)),
    };
}

export default connect(mapProperties, mapActions)(CustomersBynder);
