const customerTypes = {
    STANDALONE: 'STANDALONE',
    BYNDER: 'BYNDER',
};

const customerTypeOptions = [
    {
        value: customerTypes.STANDALONE,
        label: 'Standalone',
    },
    {
        value: customerTypes.BYNDER,
        label: 'Bynder',
    },
];

export default customerTypes;
export { customerTypes, customerTypeOptions };
