import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Fonts from './Fonts';
import Font from './Font';

const FontsContainer = () => {
    return (
        <Switch>
            <Route path="/fonts/:fontId" component={Font} />
            <Route path="/fonts/" component={Fonts} />
        </Switch>
    );
};

export default FontsContainer;
