import React, { Component } from 'react';
import jq from 'jquery';
import FontService from '../../services/FontsService';
import { maxLengthValidator, requiredValidator, urlValidator, uuidValidator } from '../../helpers/validators';

export default class AddFontFileModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
        };
    }

    validators = {
        name: [requiredValidator('Name is required'), maxLengthValidator('Max allowed 100 characters', 100)],
        file: [requiredValidator('File is required')],
    };

    componentDidMount() {
        const form = document.forms.namedItem('fontUpload');
        form.addEventListener(
            'submit',
            function (ev) {
                ev.preventDefault();
            },
            false,
        );

        jq('#addFontFileModal').on('hidden.bs.modal', this.onModalClose);
    }

    validate() {
        const result = [];
        Object.entries(this.validators).forEach(([fieldName, fieldValidators]) => {
            fieldValidators.forEach((validator) => {
                const errorMessage = validator(document.getElementById(fieldName).value);
                errorMessage && result.push(errorMessage);
            });
        });
        return result;
    }

    handleSave = async () => {
        const errors = this.validate();
        this.setState({
            messages: errors.map((e) => e.msg),
        });
        if (errors.length) {
            return;
        }

        const {
            addProps: { fontId },
            addRowCb,
        } = this.props;
        const form = document.forms.namedItem('fontUpload');
        const oData = new FormData(form);

        const response = await FontService.addFontFile(fontId, oData);
        if (response.status === 200) {
            addRowCb();
            jq('#addFontFileModal').modal('hide');
            this.setState({ messages: [] });
        } else {
            this.setState({ messages: [response.json.msg] });
        }
    };

    onModalClose = () => {
        const form = document.forms.namedItem('fontUpload');
        form.reset();

        this.setState({
            messages: [],
        });
    };

    render() {
        const { messages } = this.state;
        const {
            addProps: { styleOptions },
        } = this.props;

        return (
            <div>
                <div className="ml-auto">
                    <div
                        className="modal fade "
                        id="addFontFileModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add font file</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {(messages.length && (
                                        <div>
                                            {messages.map((message, index) => {
                                                return (
                                                    <div key={index}>
                                                        <span className={'badge badge-danger'}>{message}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )) ||
                                        null}

                                    <form encType="multipart/form-data" name="fontUpload">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input className="form-control" id={'name'} name="name" required={true} />
                                        </div>
                                        {/*<div className="form-group">
                                        <label>Style</label>
                                        <select className="form-control" id={'meta'} name={'tag'}>
                                            <option value={''}>-</option>
                                            <option value={'NORMAL'}>Normal</option>
                                            <option value={'ITALIC'}>Italic</option>
                                            <option value={'OBLIQUE'}>Oblique</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Weight</label>
                                        <select className="form-control" id={'weight'} name={'weight'}>
                                            {fontWeightOptions.map(fontWeight => <option key={fontWeight} value={fontWeight}>{fontWeight}</option>)}
                                        </select>
                                    </div>*/}
                                        <div className="form-group">
                                            <label>Rich Text Type</label>
                                            <select className="form-control" id={'style'} name={'style'}>
                                                {styleOptions.map((style) => (
                                                    <option key={style.value} value={style.value}>
                                                        {style.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>File *</label>
                                            <div>
                                                <input
                                                    id={'file'}
                                                    type="file"
                                                    name="file"
                                                    accept={'.otf, .ttf'}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Render file</label>
                                            <div>
                                                <input id={'renderFile'} type="file" name="renderFile" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                        Close
                                    </button>
                                    <button type="button" onClick={() => this.handleSave()} className="btn btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
