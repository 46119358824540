import React, { useState } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import CustomersStandalone from './CustomersStandalone';
import CustomersBynder from './CustomersBynder';
import Customer from './Customer';
import { debounce } from '../../helpers/common';
import Search from '../components/Search';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';

const CustomersContainer = ({ match }) => {
    const { path, url } = match;
    const [search, setSearch] = useState('');
    const isActive = (param) => window.location.pathname === `${path}/${param}`;
    const onSearch = debounce(
        (query) => {
            setSearch(query);
        },
        500,
        false,
    );

    return (
        <div className="h-100">
            <div className="form-row py-3">
                <div className="col-auto">
                    <h4 className="display-5 m-0">Customers</h4>
                </div>
            </div>

            <div className="card table-container">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        {AuthorizationHelper.isSuperAdmin() && (
                            <li className="nav-item">
                                <NavLink
                                    className={'btn nav-link'}
                                    activeClassName={'active'}
                                    to={`${path}/standalone`}
                                    onClick={(e) => {
                                        if (isActive('standalone')) {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    Standalone
                                </NavLink>
                            </li>
                        )}
                        <li className="nav-item">
                            <NavLink
                                className={'btn nav-link'}
                                activeClassName={'active'}
                                to={`${path}/bynder`}
                                onClick={(e) => {
                                    if (isActive('bynder')) {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                Bynder
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <Search label={'Find customer:'} onSearch={onSearch} />
                <div className={'card-body p-0'}>
                    <Switch>
                        {AuthorizationHelper.isSuperAdmin() && (
                            <Route
                                path={`${path}/standalone`}
                                render={(props) => <CustomersStandalone search={search} {...props} />}
                            />
                        )}
                        <Route
                            path={`${path}/bynder`}
                            render={(props) => <CustomersBynder search={search} {...props} />}
                        />
                        {AuthorizationHelper.isSuperAdmin() && (
                            <Route path={`${path}/:customerId`} component={Customer} />
                        )}
                        {AuthorizationHelper.isSuperAdmin() ? (
                            <Redirect path={`${path}`} to={`${url}/standalone`} />
                        ) : (
                            <Redirect path={`${path}`} to={`${url}/bynder`} />
                        )}
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default CustomersContainer;
