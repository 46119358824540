import React, { Component } from 'react';
import PropTypes from 'prop-types';
import jq from 'jquery';
import customerTypes, { customerTypeOptions } from '../../configs/customerTypes';
import accountTypes, { accountTypeOptions } from '../../configs/accountTypes';
import { requiredValidator, urlValidator, uuidValidator, brandIdValidator } from '../../helpers/validators';

export default class DuplicateCustomerModal extends Component {
    defaultValues = {
        type: customerTypes.STANDALONE,
        accountType: accountTypes.BASIC,
        name: '',
        bynderAccountId: '',
        bynderPortalUrl: '',
        bynderBrandId: '',
    };

    validators = {
        type: [],
        accountType: [],
        name: [requiredValidator('Name is required')],
        bynderAccountId: [
            requiredValidator('Bynder account ID is required'),
            uuidValidator('Bynder account ID should be valid uuid'),
        ],
        bynderPortalUrl: [
            requiredValidator('Bynder Portal Url is required'),
            urlValidator('Bynder Portal Url should be valid url'),
        ],
        bynderBrandId: [
            brandIdValidator('Brand ID should be in the following format: XXXXXXXX-XXXX-XXXX-XXXXXXXXXXXXXXXX'),
        ],
    };

    constructor(props) {
        super(props);

        this.state = {
            values: { ...this.defaultValues },
            messages: [],
        };
    }

    componentDidMount() {
        jq('#duplicateCustomerModal').on('hidden.bs.modal', this.onModalClose);
    }

    componentDidUpdate(prevProps) {
        const { customer } = this.props;

        if (prevProps.customer !== customer && customer) {
            const values = {
                ...this.state.values,
                type: customer.type,
                accountType: customer.accountType,
                name: customer.name + '-copy',
            };

            this.setState({ values });
        }
    }

    handleValueChange(fieldName, value) {
        const values = {
            ...this.state.values,
            [fieldName]: value,
        };

        this.setState({ values });
    }

    validate() {
        const { values } = this.state;
        const result = [];
        Object.entries(this.validators).forEach(([fieldName, fieldValidators]) => {
            if (
                values.type === customerTypes.STANDALONE &&
                ['bynderAccountId', 'bynderPortalUrl', 'bynderBrandId'].includes(fieldName)
            ) {
                return;
            }
            fieldValidators.forEach((validator) => {
                const errorMessage = validator(values[fieldName]);
                errorMessage && result.push(errorMessage);
            });
        });
        return result;
    }

    onModalClose = () => {
        this.setState({
            messages: [],
            values: this.defaultValues,
        });

        this.props.onClose();
    };

    closeModal = () => {
        jq('#duplicateCustomerModal').modal('hide');
    };

    handleSave() {
        const errors = this.validate();
        this.setState({
            messages: errors.map((e) => e.msg),
        });
        if (errors.length) {
            return;
        }

        const { values } = this.state;
        const dataToSend = { ...values, id: this.props.customer.id };
        if (values.type === customerTypes.STANDALONE) {
            delete dataToSend.bynderAccountId;
            delete dataToSend.bynderPortalUrl;
            delete dataToSend.bynderBrandId;
        }
        this.props.onDuplicateRequest(dataToSend);
        this.closeModal();
    }

    render() {
        const { customer } = this.props;
        const { values, messages } = this.state;

        return (
            <div
                className="modal fade "
                id="duplicateCustomerModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Duplicate customer "{(customer && customer.name) || ''}"</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Customer type</label>
                                <select
                                    value={values['type']}
                                    className="form-control"
                                    onChange={(e) => this.handleValueChange('type', e.target.value)}
                                >
                                    {customerTypeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    type="text"
                                    value={values['name']}
                                    onChange={(e) => this.handleValueChange('name', e.target.value)}
                                    className="form-control"
                                />
                            </div>

                            {(values.type === customerTypes.BYNDER && (
                                <div className="form-group">
                                    <label>Bynder account id</label>
                                    <input
                                        type="text"
                                        value={values['bynderAccountId']}
                                        onChange={(e) => this.handleValueChange('bynderAccountId', e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                            )) ||
                                null}

                            {(values.type === customerTypes.BYNDER && (
                                <div className="form-group">
                                    <label>Bynder portal url</label>
                                    <input
                                        type="text"
                                        value={values['bynderPortalUrl']}
                                        onChange={(e) => this.handleValueChange('bynderPortalUrl', e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                            )) ||
                                null}

                            {(values.type === customerTypes.BYNDER && (
                                <div className="form-group">
                                    <label>Brand ID</label>
                                    <input
                                        type="text"
                                        value={values['bynderBrandId']}
                                        onChange={(e) => this.handleValueChange('bynderBrandId', e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                            )) ||
                                null}

                            <div className="form-group">
                                <label>Account type</label>
                                <select
                                    value={values['accountType']}
                                    className="form-control"
                                    onChange={(e) => this.handleValueChange('accountType', e.target.value)}
                                >
                                    {accountTypeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {(messages.length && (
                                <div>
                                    {messages.map((message, index) => {
                                        return (
                                            <div key={index}>
                                                <span className={'badge badge-danger'}>{message}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            )) ||
                                null}
                        </div>
                        <div className="modal-footer">
                            <div>All content from the selected customer will be copied to the new customer</div>
                            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>
                                Close
                            </button>
                            <button type="button" onClick={() => this.handleSave()} className="btn btn-primary">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DuplicateCustomerModal.propTypes = {
    customer: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(customerTypes)).isRequired,
        accountType: PropTypes.oneOf(Object.values(accountTypes)).isRequired,
    }),
    onDuplicateRequest: PropTypes.func,
    onClose: PropTypes.func,
};
