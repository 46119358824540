import React, { useState, forwardRef, useImperativeHandle } from 'react';

const Search = forwardRef(({ label, onSearch }, ref) => {
    const [search, setSearch] = useState('');
    const onChange = (value) => {
        setSearch(value);
        onSearch(value);
    };

    const onClear = () => {
        setSearch('');
        onSearch('');
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                onClear() {
                    setSearch('');
                },
            };
        },
        [],
    );

    return (
        <div className="search-filter">
            <label>{label}</label>
            <input
                type="text"
                id="search"
                name={label}
                onChange={(e) => onChange(e.target.value)}
                value={search}
                autoComplete="off"
                ref={ref}
            />
            {search && (
                <button className="btn btn-sm" onClick={onClear}>
                    ×
                </button>
            )}
        </div>
    );
});

export default Search;
