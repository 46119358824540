import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logoutRequest } from '../../store/user/user.actions';
import Logo from '../../images/logo_white.svg';
import permissions from '../../configs/permissions';
import AuthorizationAllowBlock from '../../common/AuthorizationBlock/AuthorizationAllowBlock';
import { roles } from '../../configs/roles';

class Header extends Component {
    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        this.props.logoutRequest();
    }

    render() {
        return (
            <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-dark text-white shadow">
                <img src={Logo} height="40px" className="mr-4" />
                <nav className="nav mr-auto">
                    <AuthorizationAllowBlock permissions={[permissions.CUSTOMERS.READ]}>
                        <NavLink
                            className="p-2 text-white nav-link"
                            activeClassName="btn btn-light btn-sm text-dark"
                            to="/customers"
                        >
                            Customers
                        </NavLink>
                    </AuthorizationAllowBlock>

                    <AuthorizationAllowBlock
                        roles={[roles.SYSTEM_ADMIN]}
                        permissions={[permissions.USERS.READ, permissions.CUSTOMERS.READ]}
                        checkAllPermissions
                    >
                        <NavLink
                            className="p-2 text-white nav-link"
                            activeClassName="btn btn-light btn-sm text-dark"
                            to="/users"
                        >
                            Users
                        </NavLink>
                    </AuthorizationAllowBlock>

                    <AuthorizationAllowBlock permissions={[permissions.FONTS.READ]}>
                        <NavLink
                            className="p-2 text-white nav-link"
                            activeClassName="btn btn-light btn-sm text-dark"
                            to="/fonts"
                        >
                            Fonts
                        </NavLink>
                    </AuthorizationAllowBlock>

                    <AuthorizationAllowBlock permissions={[permissions.GALLERY_TEMPLATES.READ]}>
                        <NavLink
                            className="p-2 text-white nav-link"
                            activeClassName="btn btn-light btn-sm text-dark"
                            to="/templates"
                        >
                            Gallery Templates
                        </NavLink>
                    </AuthorizationAllowBlock>

                    <AuthorizationAllowBlock
                        roles={[roles.SYSTEM_ADMIN]}
                        permissions={[permissions.RENDITION_PROFILES.READ]}
                    >
                        <NavLink
                            className="p-2 text-white nav-link"
                            activeClassName="btn btn-light btn-sm text-dark"
                            to="/renditions"
                        >
                            Renditions
                        </NavLink>
                    </AuthorizationAllowBlock>
                </nav>
                <button className="btn btn-outline-light" onClick={this.handleLogout}>
                    Logout
                </button>
            </div>
        );
    }
}

function mapProperties(state, ownProps) {
    return {};
}

function mapActions(dispatch) {
    return {
        logoutRequest: () => {
            dispatch(logoutRequest());
        },
    };
}

export default connect(mapProperties, mapActions)(Header);
