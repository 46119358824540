import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../store/user/user.actions';
import { Redirect } from 'react-router-dom';
import Logo from '../../images/logo_blue.svg';
import BackgroundImg from '../../images/background.jpg';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            pass: '',
        };
    }
    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }
    handlePassChange(e) {
        this.setState({ pass: e.target.value });
    }
    login() {
        this.props.login(this.state.email, this.state.pass);
    }
    render() {
        const { user } = this.props;
        return (
            <div className="d-flex justify-content-center align-items-center h-100">
                {user && <Redirect to={'/'} />}
                <img className="position-absolute top-0 w-100 h-100" src={BackgroundImg} alt="background" />
                <div className="card py-3" style={{ width: '20rem' }}>
                    <img className="card-img-top px-5" src={Logo} alt="logo" />
                    <div className="card-body">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                return this.login();
                            }}
                        >
                            <div className="form-group">
                                <label>Email address</label>
                                <input
                                    value={this.state.email}
                                    onChange={(e) => this.handleEmailChange(e)}
                                    type="email"
                                    className="form-control"
                                    placeholder="Email address"
                                    required
                                    autoFocus
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    value={this.state.pass}
                                    onChange={(e) => this.handlePassChange(e)}
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    required
                                />
                            </div>

                            <button className="btn btn-primary btn-block" type="submit">
                                Sign in
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapActions(dispatch) {
    return {
        login: (email, password) => {
            dispatch(login(email, password));
        },
    };
}

function mapProperties(state, ownProps) {
    const { loggingIn, lastLoginFail, user } = state.user;
    return {
        loggingIn,
        lastLoginFail,
        user,
    };
}

export default connect(mapProperties, mapActions)(Login);
