import React, { Fragment } from 'react';
import startCase from 'lodash/startCase';
import isArray from 'lodash/isArray';

const properties = ['type', 'tool', '', 'containerType', 'extension', '', 'downloadable', 'disabled', 'dynamic'];

const splitName = (name) => {
    const arr = name.trim().split(' ');
    const val = arr.pop();
    const str = arr.join(' ');

    return [str, val];
};

const compare = (a, b) => {
    if (a.length !== b.length) {
        return a.length - b.length;
    }

    return a.trim().localeCompare(b.trim());
};

const sortConfiguration = (a, b) => {
    const [aName, aVal] = splitName(a.name);
    const [bName, bVal] = splitName(b.name);

    if (aName !== bName) {
        return compare(bName, aName);
    }

    return compare(bVal, aVal);
};

function Value({ value }) {
    if (value === null || value === false) {
        return <span className="text-danger">No</span>;
    }

    if (value === true) {
        return <span className="text-success">Yes</span>;
    }

    if (isArray(value)) {
        return value.map((val) => (
            <span className="text-primary mr-1" key={val}>
                {val}
            </span>
        ));
    }

    return value || null;
}

export default function RenditionProfileConfigurations({ configurations }) {
    if (!configurations) {
        return null;
    }

    return (
        <div className="form-group">
            {configurations.sort(sortConfiguration).map((configuration) => (
                <details key={configuration.id}>
                    <summary>{configuration.name}</summary>
                    <dl className="row p-2">
                        {properties.map((key, index) => (
                            <Fragment key={index}>
                                <dd className="col-md-2 col-sm-6 text-black-50">{startCase(key)}</dd>
                                <dd className="col-md-2 col-sm-6 text-monospace">
                                    {!!key && <Value value={configuration[key]} />}
                                </dd>
                            </Fragment>
                        ))}
                    </dl>
                </details>
            ))}
        </div>
    );
}
