import ServerConnector from './Serverconnector';

class RenditionsService extends ServerConnector {
    constructor(path = 'api/renditions') {
        super(path);
    }

    getRenditions() {
        const options = {
            method: 'GET',
        };
        return this.send({ path: '/', options });
    }
}

export default new RenditionsService();
