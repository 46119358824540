import { logout } from '../store/user/user.actions';

export default class ServerConnector {
    constructor(path) {
        this.path = '/' + path;
    }

    static setStore(store) {
        ServerConnector.store = store;
    }
    handleErrors(res) {
        if (res.status === 401) {
            ServerConnector.store.dispatch(logout());
        } else {
            return res;
        }
    }
    send(req, isJson = true) {
        const path = `${this.path}${req.path}`;

        const headersObj = Object.assign(
            {
                'Cache-Control': 'no-cache',
            },
            req.headers,
        );
        if (isJson) {
            headersObj['content-type'] = 'application/json';
        }

        const headers = new Headers(headersObj);

        const options = Object.assign(
            {
                method: 'POST',
                credentials: 'same-origin',
            },
            req.options,
        );

        options.headers = headers;
        const request = new Request(path, options);
        return fetch(request)
            .then(this.handleErrors)
            .then((res) => {
                return res.json().then((json) => {
                    return { status: res.status, json: json };
                });
            })
            .catch((error) => {
                return { status: error.message, json: {} };
            });
    }
}
