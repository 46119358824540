import React, { Component } from 'react';
import customersService from './../../services/CustomersService';
import AdminTable from './../../common/AdminTable/AdminTable';
import usersService from './../../services/UsersService';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import permissions from '../../configs/permissions';
import { requiredValidator } from '../../helpers/validators';

const emailRequiredValidator = requiredValidator('Email is required');

export default class UsersStandalone extends Component {
    constructor(props) {
        super(props);
        this.texts = {
            addText: 'Add user',
            editTitle: 'Edit user',
        };

        this.state = {
            users: [],
            customers: [],
            customersByIds: {},
        };
    }

    componentDidMount() {
        customersService.getCustomers('STANDALONE').then(({ status, json }) => {
            if (status === 200) {
                const customersByIds = json.items.reduce((result, currentObject) => {
                    result[+currentObject.id] = currentObject;
                    return result;
                }, {});
                this.setState({ customers: json.items, customersByIds });
            }
        });
    }

    resendActivation(id) {
        usersService.resendActivation(id).then(({ status }) => {
            if (status !== 200) {
                alert('fail to send');
            } else {
                alert('success');
            }
        });
    }

    render() {
        const { customers, customersByIds } = this.state;

        if (!customers.length) return <div />;

        return (
            <AdminTable
                texts={this.texts}
                editType={!AuthorizationHelper.hasPermissions([permissions.USERS.WRITE]) ? 'none' : 'modal'}
                hideAdd={!AuthorizationHelper.hasPermissions([permissions.USERS.WRITE])}
                hideDelete={!AuthorizationHelper.hasPermissions([permissions.USERS.DELETE])}
                edit={(d) => usersService.editUser(d)}
                add={(d) => usersService.addUser({ ...d, type: 'STANDALONE' })}
                delete={(id) => usersService.deleteUser(id)}
                fetchData={(page) => usersService.getUsers('STANDALONE', this.props.search, page)}
                search={this.props.search}
                cols={[
                    {
                        title: 'Email',
                        name: 'email',
                        display: (val, user) => {
                            return val ? val : user.firstName ? user.firstName + ' ' + user.lastName : null;
                        },
                        validator: emailRequiredValidator,
                    },
                    // {
                    //     title: 'Customers',
                    //     name: 'customers',
                    //     type: 'checkboxGroup',
                    //     ignoreInAdd: false,
                    //     options: customers,
                    //     display: (val) =>
                    //         val.map((e) => {
                    //             const customer = customersByIds[+e.id];
                    //             if (!customer) {
                    //                 return null;
                    //             }

                    //             return (
                    //                 <span key={customer.id} className="badge badge-dark mr-1">
                    //                     {customer.name}
                    //                 </span>
                    //             );
                    //         }),
                    // },
                    {
                        title: 'Admin Roles',
                        name: 'adminRoles',
                        ignoreInAdd: true,
                        ignoreInEdit: true,
                        display: (adminRoles) =>
                            !adminRoles
                                ? null
                                : adminRoles.map((roles) => (
                                      <span key={roles} className="badge badge-danger mr-1">
                                          {roles}
                                      </span>
                                  )),
                    },
                    {
                        title: 'Resend',
                        ignoreInAdd: true,
                        ignoreInEdit: true,
                        name: 'id',
                        display: (val, user) =>
                            user.active ? null : (
                                <button className="btn btn-sm btn-secondary" onClick={() => this.resendActivation(val)}>
                                    Resend
                                </button>
                            ),
                    },
                ]}
            />
        );
    }
}
