import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getUser, logoutRequest } from './store/user/user.actions';
import { readCookie } from './helpers/cookie';

class PrivateRoute extends Component {
    render() {
        const { getUser, component: RouteComponent, user, ...rest } = this.props;
        const userIdInPath = parseInt(this.props.location.pathname.split('/')[1])
            ? parseInt(this.props.location.pathname.split('/')[1]).toString()
            : false;
        const currentCompany = this.props.currentCompany || userIdInPath || readCookie('currentCompany');
        const hasToken = !!readCookie('hasToken');
        //const currentCompany = readCookie('currentCompany');
        let Component;
        if (user) {
            Component = (props) => <RouteComponent {...props} />;
        } else if (hasToken) {
            Component = () => <div></div>;
        } else {
            Component = () => (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: this.props.location },
                    }}
                />
            );
        }
        if (!user && hasToken) {
            getUser(currentCompany);
        }

        return <Route {...rest} render={(props) => Component(props)} />;
    }
}

const mapProperties = (state, ownProps) => {
    const { user, loggingIn } = state.user;
    return {
        user,
        loggingIn,
        ...ownProps,
    };
};

const mapActions = (dispatch) => {
    return {
        getUser: () => {
            dispatch(getUser());
        },
        logout() {
            dispatch(logoutRequest());
        },
    };
};

export default connect(mapProperties, mapActions)(PrivateRoute);
