import React, { useMemo, memo } from 'react';
import { getOptionsArr } from '../../helpers/common';

const Pagination = ({ changePageCb, page, isLoading, hasMore, totalItems }) => {
    const changePage = (pageNum) => changePageCb(pageNum);
    const handleClick = (direction) => () => {
        const newPageNum = direction === 'prev' ? page - 1 : page + 1;
        changePage(newPageNum);
    };

    const isPrevDisabled = useMemo(() => page === 0 || isLoading, [page, isLoading]);
    const isNextDisabled = useMemo(() => !hasMore || isLoading, [hasMore, isLoading]);

    const optionsArr = useMemo(() => getOptionsArr(totalItems), [totalItems]);

    return (
        <div className="pagination">
            <button disabled={isPrevDisabled} className="btn btn-primary" onClick={handleClick('prev')}>
                &#x2190; Prev
            </button>
            {optionsArr.length < 2 ? (
                <div>{page + 1}</div>
            ) : (
                <select onChange={(e) => changePage(Number(e.target.value) - 1)} disabled={optionsArr.length === 1}>
                    {optionsArr.map((optionValue) => (
                        <option key={optionValue} selected={optionValue === page + 1}>
                            {optionValue}
                        </option>
                    ))}
                </select>
            )}
            <button disabled={isNextDisabled} className="btn btn-primary" onClick={handleClick('next')}>
                Next &#x2192;
            </button>
        </div>
    );
};

export default memo(Pagination);
