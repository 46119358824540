import * as types from '../types';
const initialState = {
    resetRequestFail: null,
    updateFail: null,
    currentCompany: '',
};
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.USER_SET_DEFAULTS:
            return {
                initialState,
            };
        case types.SELECT_COMPANY_REQUEST:
            return {
                ...state,
                currentCompany: '-1',
            };
        case types.SELECT_COMPANY_SUCCESS:
            return {
                ...state,
                currentCompany: action.company,
            };
        case types.GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.companies,
            };
        case types.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateSuccess: true,
                user: { ...state.user, ...action.user },
            };
        case types.SET_UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateSuccess: action.success,
                updateFail: action.fail,
            };
        case types.UPDATE_PROFILE_FAIL_PASS:
            return {
                ...state,
                updateSuccess: false,
                updateFail: { msg: 'Wrong password' },
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn: false,
                user: action.user,
                currentCompany: action.currentCompany || state.currentCompany,
                companies: action.companies,
                lastLoginFail: false,
            };
        case types.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case types.LOGIN_FAIL:
            return {
                ...state,
                loggingIn: false,
                lastLoginFail: true,
            };
        case types.LOGOUT_REQUEST:
            return {
                ...state,
                loggingOut: true,
            };
        case types.LOGOUT:
            return {
                ...state,
                loggingOut: false,
                user: null,
                companies: null,
            };
        case types.RESET_REQUEST:
            return {
                ...state,
                requestingReset: true,
            };
        case types.RESET_REQUEST_SUCCESS:
            return {
                ...state,
                requestingReset: false,
                resetRequestSuccess: true,
                resetRequestFail: null,
            };
        case types.RESET_REQUEST_FAIL:
            return {
                ...state,
                requestingReset: false,
                resetRequestSuccess: false,
                resetRequestFail: action.resetRequestFail,
            };
        case types.VALIDATE_TOKEN_SUCCESS:
            return {
                ...state,
                tokenIsValid: true,
                resetEmail: action.email,
            };
        case types.VALIDATE_TOKEN_FAIL:
            return {
                ...state,
                tokenIsValid: false,
            };
        case types.RESET_SUCCESS:
            return {
                ...state,
                requestingReset: true,
                resetSuccess: true,
            };
        default:
            return state;
    }
}
