import React, { Component } from 'react';
import customersService from './../../services/CustomersService';
import AdminTable from './../../common/AdminTable/AdminTable';
import usersService from './../../services/UsersService';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import permissions from '../../configs/permissions';

export default class UsersBynder extends Component {
    constructor(props) {
        super(props);
        this.texts = {
            addText: 'Add user',
            editTitle: 'Edit user',
        };
        this.state = {
            users: [],
            customers: [],
            customersByIds: {},
        };
    }

    componentDidMount() {
        customersService.getCustomers('BYNDER').then(({ status, json }) => {
            if (status === 200) {
                const customersByIds = json.items.reduce((result, currentObject) => {
                    result[+currentObject.id] = currentObject;
                    return result;
                }, {});
                this.setState({ customers: json.items });
            }
        });
    }

    render() {
        const { customers, customersByIds } = this.state;
        if (!customers.length) return <div />;

        return (
            <AdminTable
                texts={this.texts}
                editType="none"
                hideAdd={true}
                hideDelete={!AuthorizationHelper.hasPermissions([permissions.USERS.DELETE])}
                delete={(id) => usersService.deleteUser(id)}
                fetchData={(page) => usersService.getUsers('BYNDER', page)}
                cols={[
                    {
                        title: 'Email',
                        name: 'email',
                        display: (val, user) => {
                            return val ? val : user.firstName ? user.firstName + ' ' + user.lastName : null;
                        },
                    },
                    {
                        title: 'Bynder User ID',
                        name: 'bynderUserId',
                    },
                    // {
                    //     title: 'Customers',
                    //     name: 'customers',
                    //     type: 'checkboxGroup',
                    //     ignoreInAdd: false,
                    //     options: customers,
                    //     display: (val) =>
                    //         val
                    //             .map((e) => {
                    //                 const customer = customersByIds[+e.id];
                    //                 if (!customer) {
                    //                     return null;
                    //                 }

                    //                 return (
                    //                     <span key={customer.id} className="badge badge-dark mr-1">
                    //                         {customer.name}
                    //                     </span>
                    //                 );
                    //             }),
                    // },
                    {
                        title: 'Admin Roles',
                        name: 'adminRoles',
                        ignoreInAdd: true,
                        ignoreInEdit: true,
                        display: (adminRoles) =>
                            !adminRoles
                                ? null
                                : adminRoles.map((roles) => (
                                      <span key={roles} className="badge badge-danger mr-1">
                                          {roles}
                                      </span>
                                  )),
                    },
                ]}
            />
        );
    }
}
