import ServerConnector from './Serverconnector';

class UsersService extends ServerConnector {
    constructor(path = 'api/users') {
        super(path);
    }

    addUser(data) {
        const options = {
            method: 'PUT',
            body: JSON.stringify(data),
        };
        return this.send({ path: '/', options });
    }

    deleteUser(id) {
        const options = {
            method: 'DELETE',
        };
        return this.send({ path: `/${id}`, options });
    }

    editUser(data) {
        const options = {
            method: 'PUT',
            body: JSON.stringify(data),
        };
        return this.send({ path: `/${data.id}`, options });
    }

    getUsers(userType, search = '', page = 0) {
        const options = { method: 'GET' };
        return this.send({ path: `/?type=${userType}&query=${search}&page=${page}`, options });
    }

    resendActivation(identityId) {
        const options = { method: 'POST' };
        return this.send({ path: `/${identityId}/resend`, options });
    }
}

export default new UsersService();
