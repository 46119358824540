import React, { Component } from 'react';
import { connect } from 'react-redux';

import Body from './app/Body';
import Header from './header/Header';
import Messages from './messages/Messages';

class App extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { user } = this.props;
        return (
            <div key={'app'} className="m-0 bg-light h-100">
                <Messages />

                <div className="sticky-top">
                    <Header {...this.props} />
                </div>
                <div className="container">
                    <Body {...this.props} />
                </div>
            </div>
        );
    }
}

function mapProperties(state, ownProps) {
    const { user } = state.user;
    return {
        ...ownProps,
        user,
    };
}

export default connect(mapProperties)(App);
