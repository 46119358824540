//auth
export const USER_SET_DEFAULTS = 'USER_SET_DEFAULTS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS';
export const RESET_REQUEST_FAIL = 'RESET_REQUEST_FAIL';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAIL = 'VALIDATE_TOKEN_FAIL';
export const TOKEN_RECEIVED = 'TOKEN_RECEIVED';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL_PASS = 'UPDATE_PROFILE_FAIL_PASS';
export const SET_UPDATE_USER_SUCCESS = 'SET_UPDATE_USER_SUCCESS';
export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';
export const SELECT_COMPANY_REQUEST = 'SELECT_COMPANY_REQUEST';
export const SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS';
export const SELECT_COMPANY_PAGE_LOADED = 'SELECT_COMPANY_PAGE_LOADED';
//end auth

//messages
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE';
export const MESSAGE_ADD = 'MESSAGE_ADD';
export const MESSAGE_RESET_LIST = 'MESSAGE_RESET_LIST';
//end messages
