import { roles } from '../configs/roles';

class AuthorizationHelper {
    roles = [];
    currentPermissions = [];

    setPermissions = (permissions) => {
        this.currentPermissions = permissions;
    };

    setRoles = (roles) => {
        this.roles = roles;
    };

    hasPermission = (requestedPermission) => {
        return this.currentPermissions.includes(requestedPermission);
    };

    hasPermissions = (requestedPermissions) => {
        return requestedPermissions.every((rp) => this.currentPermissions.includes(rp));
    };

    hasOneOfThePermissions = (requestedPermissions) => {
        return requestedPermissions.some((rp) => this.currentPermissions.includes(rp));
    };

    hasRole = (role) => {
        return this.roles.includes(role);
    };

    isSuperAdmin = () => {
        return this.hasRole(roles.SYSTEM_ADMIN);
    };
}

export default new AuthorizationHelper();
