const ITEMS_SIZE_PER_PAGE = 100;

const getOptionsArr = (totalItems, sizePerPage = ITEMS_SIZE_PER_PAGE) => {
    const lastPageNum = totalItems < sizePerPage ? 1 : Math.ceil(totalItems / sizePerPage);
    if (lastPageNum < 2) return [1];

    const result = [];
    for (let i = 1; i <= lastPageNum; i++) result.push(i);

    return result;
};

function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        let context = this;
        let args = arguments;

        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
}

module.exports = {
    getOptionsArr,
    debounce,
};
