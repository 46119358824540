import React, { memo } from 'react';
import { FixedSizeList as List } from 'react-window';

const CheckBox = memo(({ index, style, data }) => {
    const { items, changeItem } = data;
    const item = items[index];

    return (
        <div style={style}>
            <label className="custom-control custom-checkbox custom-control-inline">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={item.value}
                    onChange={() => changeItem(index)}
                />
                <div className="custom-control-label">{item.name}</div>
            </label>
        </div>
    );
});

export const CheckBoxGroup = memo(({ items, changeItem }) => (
    <div>
        {items && (
            <List height={450} itemCount={items.length} itemSize={35} width={460} itemData={{ items, changeItem }}>
                {CheckBox}
            </List>
        )}
    </div>
));
