import React, { Component } from 'react';

import AdminTable from './../../common/AdminTable/AdminTable';
import TemplatesService from './../../services/TemplatesService';
import customersService from '../../services/CustomersService';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import permissions from '../../configs/permissions';

export default class Fonts extends Component {
    constructor() {
        super();
        this.texts = {
            pageTitle: 'Gallery Templates',
            addText: 'Add template',
        };
        this.state = {
            active: AuthorizationHelper.isSuperAdmin() ? 'global' : 'custom',
            customers: [],
        };
    }

    updateCustomers() {
        customersService.getCustomers().then(({ status, json }) => {
            if (status === 200) {
                this.setState({ customers: json.items });
            }
        });
    }

    componentDidMount() {
        this.updateCustomers();
    }

    setActiveTab(tab) {
        this.setState({
            active: tab,
        });
    }

    deleteTemplate(id) {
        return TemplatesService.deleteTemplate(id).then((json) => {
            this.updateCustomers();
            return json;
        });
    }

    openFiles(id, isGlobal) {
        this.setState({ openedFile: id });
    }

    _isJSONString(v) {
        try {
            JSON.parse(v);
        } catch (e) {
            return { msg: 'invalid json' };
        }
        return null;
    }

    render() {
        const { pageTitle } = this.texts;
        const customers = this.state.customers;
        const { active } = this.state;
        if (!customers.length) return <div />;
        return (
            <div className="h-100">
                <div className="form-row py-3">
                    <div className="col-auto">
                        <h4 className="display-5 m-0">{pageTitle}</h4>
                    </div>
                    {/*<div className="col text-right">*/}
                    {/*<NavLink to={'/templates/export'} className="btn btn-success btn-lg mt-3">Export*/}
                    {/*templates</NavLink>*/}
                    {/*</div>*/}
                </div>
                <div className="card table-container">
                    <div className="card-header">
                        <ul className="nav nav-tabs card-header-tabs">
                            {AuthorizationHelper.isSuperAdmin() && (
                                <li className="nav-item">
                                    <a
                                        onClick={() => this.setActiveTab('global')}
                                        className={`btn nav-link ${active === 'global' ? 'active' : ''}`}
                                    >
                                        Global
                                    </a>
                                </li>
                            )}
                            <li className="nav-item">
                                <a
                                    onClick={() => this.setActiveTab('custom')}
                                    className={`btn nav-link ${active !== 'global' ? 'active' : ''}`}
                                >
                                    Custom
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body p-0">
                        {active === 'global' ? (
                            <AdminTable
                                key="global"
                                addProps={{ global: true }}
                                hideAdd={
                                    !AuthorizationHelper.hasPermissions([permissions.GALLERY_TEMPLATES.WRITE]) ||
                                    active !== 'global'
                                }
                                hideDelete={!AuthorizationHelper.hasPermissions([permissions.GALLERY_TEMPLATES.DELETE])}
                                texts={{ addText: 'Add global template' }}
                                edit={(d) => TemplatesService.editTemplate(d)}
                                add={(d) => TemplatesService.addTemplate({ ...d, global: true })}
                                delete={(id) => TemplatesService.deleteTemplate(id)}
                                editType="none"
                                fetchData={() => TemplatesService.getTemplateByType('global')}
                                cols={[
                                    {
                                        title: 'Name',
                                        name: 'name',
                                        validator: (v) => {
                                            return v.length ? null : { msg: 'Name is required' };
                                        },
                                    },
                                    {
                                        title: 'Creative Id',
                                        name: 'creativeId',
                                        ignoreInEdit: true,
                                        hide: true,
                                    },
                                    {
                                        title: 'Template',
                                        name: 'template',
                                        validator: (v) => {
                                            return this._isJSONString(v);
                                        },
                                        ignoreInEdit: true,
                                        hide: true,
                                        type: 'textArea',
                                        ignoreInAdd: true,
                                    },
                                    {
                                        title: 'Creative Type',
                                        name: 'creativeType',
                                        type: 'select',
                                        options: [
                                            { value: 'IMAGE', label: 'Image' },
                                            { value: 'VIDEO', label: 'Video' },
                                        ],
                                    },
                                ]}
                            />
                        ) : (
                            <AdminTable
                                key="custom"
                                addProps={{ global: false }}
                                hideAdd={
                                    !AuthorizationHelper.hasPermissions([permissions.GALLERY_TEMPLATES.WRITE]) ||
                                    active === 'global'
                                }
                                hideDelete={!AuthorizationHelper.hasPermissions([permissions.GALLERY_TEMPLATES.DELETE])}
                                texts={{ addText: 'Add custom template', editTitle: 'Edit template' }}
                                edit={(d) => TemplatesService.editTemplate(d)}
                                add={(d) => TemplatesService.addTemplate({ ...d, global: false })}
                                delete={(id) => this.deleteTemplate(id)}
                                editType={
                                    !AuthorizationHelper.hasPermissions([permissions.GALLERY_TEMPLATES.WRITE])
                                        ? 'none'
                                        : 'modal'
                                }
                                fetchData={() => TemplatesService.getTemplateByType('custom')}
                                cols={[
                                    {
                                        title: 'Name',
                                        name: 'name',
                                        validator: (v) => {
                                            return v.length ? null : { msg: 'Name is required' };
                                        },
                                        ignoreInEdit: true,
                                    },
                                    {
                                        title: 'Creative Id',
                                        name: 'creativeId',
                                        ignoreInEdit: true,
                                        hide: true,
                                    },
                                    {
                                        title: 'Template',
                                        name: 'template',
                                        ignoreInEdit: true,
                                        hide: true,
                                        validator: (v) => {
                                            return this._isJSONString(v);
                                        },
                                        type: 'textArea',
                                        ignoreInAdd: true,
                                    },
                                    {
                                        title: 'Customers',
                                        name: 'customers',
                                        type: 'checkboxGroup',
                                        ignoreInAdd: true,
                                        options: customers,
                                        display: (val) =>
                                            val.map((e) => (
                                                <span key={e.name} className="badge badge-dark mr-1">
                                                    {e.name}
                                                </span>
                                            )),
                                    },
                                    {
                                        title: 'Creative Type',
                                        name: 'creativeType',
                                        type: 'select',
                                        options: [
                                            { value: 'IMAGE', label: 'Image' },
                                            { value: 'VIDEO', label: 'Video' },
                                        ],
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
