/**
 * The REQUIRED field validator
 * @param message
 * @returns {function(*=): null|{msg: string}}
 */
const requiredValidator = (message = 'The field is required') => (value) => {
    return value || value === 'number' ? null : { msg: message };
};

/**
 * //Standard UUID REGEXP
 * @param message
 * @returns {function(*=): (null|{msg: string})}
 */
const uuidValidator = (message = 'The field should be valid uuid') => (value) => {
    if (!value || /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/.test(value)) {
        return null;
    }
    return { msg: message };
};

/**
 * Brand ID Validator REGEXP
 * Sample: A7F09A6F-4E01-40F6-A7C891A8D5352465
 * @param message
 * @returns {function(*=): (null|{msg: string})}
 */
const brandIdValidator = (message = 'The field should be valid uuid') => (value) => {
    if (!value || /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{16}/.test(value)) {
        return null;
    }
    return { msg: message };
};

/**
 * URL validator
 * @param message
 * @returns {function(*=): (null|{msg: string})}
 */
const urlValidator = (message = 'The field should be valid url') => (value) => {
    if (
        !value ||
        /(https:\/\/www\.|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?($|\/$|\/)$/.test(value)
    ) {
        return null;
    }
    return { msg: message };
};

/**
 * Max Length Validator
 * @param message
 * @param maxLength
 * @returns {function(*=): (null|{msg: string})}
 */
const maxLengthValidator = (message = 'Maximum length should be {{maxLength}}', maxLength) => (value) => {
    if (!value || value.length <= maxLength) {
        return null;
    }
    return { msg: message };
};

export { requiredValidator, uuidValidator, urlValidator, brandIdValidator, maxLengthValidator };
