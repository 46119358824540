import React, { useState, useEffect, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import customersService from './../../services/CustomersService';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import permissions from '../../configs/permissions';
import AuthorizationAllowBlock from '../../common/AuthorizationBlock/AuthorizationAllowBlock';
import RenditionProfileConfigurations from './RenditionProfileConfigurations';

const useCustomerSettings = (customerId) => {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [trackingEnabled, setTrackingEnabled] = useState(false);
    const [defaultRenditionProfileId, setDefaultRenditionProfileId] = useState(0);
    const [customerSettings, setCustomerSettings] = useState({});
    const [renditionProfiles, setRenditionProfiles] = useState([]);

    const save = useCallback(() => {
        setErrorMessage('');
        setUpdating(true);
        const data = {
            customerAccessibleRenditionProfilesIds: renditionProfiles.map((item) => item.id),
            defaultRenditionProfileId: defaultRenditionProfileId || null,
            trackingEnabled,
        };
        customersService
            .setCustomerSettings(customerId, data)
            .then(({ status, json }) => {
                unstable_batchedUpdates(() => {
                    if (status !== 200) {
                        setErrorMessage('Fail to save.');
                    } else {
                        setCustomerSettings(json);
                    }
                    setUpdating(false);
                });
            })
            .catch((err) => {
                unstable_batchedUpdates(() => {
                    setErrorMessage(err.message);
                    setUpdating(false);
                });
            });
    }, [customerId, trackingEnabled, defaultRenditionProfileId, renditionProfiles]);

    useEffect(() => {
        setLoading(true);
        customersService
            .getCustomerSettings(customerId)
            .then(({ json: settings }) => {
                unstable_batchedUpdates(() => {
                    setCustomerSettings(settings);
                    setRenditionProfiles(settings.customerAccessibleRenditionProfiles);
                    setLoading(false);
                });
            })
            .catch((err) => {
                unstable_batchedUpdates(() => {
                    setErrorMessage(err.message);
                    setLoading(false);
                });
            });
    }, [customerId]);

    useEffect(() => {
        setTrackingEnabled(!!customerSettings.trackingEnabled);
        setDefaultRenditionProfileId((customerSettings.defaultRenditionProfile || {}).id || 0);
    }, [customerSettings]);

    const defaultRenditionProfile = renditionProfiles.find((item) => item.id === defaultRenditionProfileId);
    const allowClearRenditionProfile = !customerSettings.defaultRenditionProfile || !renditionProfiles.length;

    return {
        save,
        loading,
        updating,
        errorMessage,
        trackingEnabled,
        setTrackingEnabled,
        renditionProfiles,
        defaultRenditionProfile,
        defaultRenditionProfileId,
        setDefaultRenditionProfileId,
        allowClearRenditionProfile,
    };
};

const Customer = ({ match }) => {
    const {
        params: { customerId },
    } = match;
    const {
        save,
        loading,
        updating,
        errorMessage,
        trackingEnabled,
        setTrackingEnabled,
        renditionProfiles,
        defaultRenditionProfile,
        defaultRenditionProfileId,
        setDefaultRenditionProfileId,
        allowClearRenditionProfile,
    } = useCustomerSettings(customerId);

    const handleChangeTrackingEnabled = useCallback(({ currentTarget }) => {
        setTrackingEnabled(currentTarget.checked);
    }, []);

    const handleChangeRenditionProfile = useCallback(({ currentTarget }) => {
        setDefaultRenditionProfileId(+currentTarget.value);
    }, []);

    if (loading) {
        return (
            <div className="p-3 d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="p-3">
            <h4 className="mb-4">Customer Settings</h4>
            {!!errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
            <div className="form-group d-flex align-items-center">
                <input
                    disabled={!AuthorizationHelper.hasPermissions([permissions.CUSTOMERS.WRITE])}
                    onChange={handleChangeTrackingEnabled}
                    checked={trackingEnabled}
                    className="mr-2"
                    id="trackingEnabled"
                    type="checkbox"
                />
                <label className="form-check-label" htmlFor="trackingEnabled">
                    Tracking enabled
                </label>
            </div>

            <div className="form-group">
                <label htmlFor="renditionProfile">Default rendition profile</label>
                <select
                    disabled={
                        !renditionProfiles.length || !AuthorizationHelper.hasPermissions([permissions.CUSTOMERS.WRITE])
                    }
                    className="custom-select"
                    id="renditionProfile"
                    onChange={handleChangeRenditionProfile}
                    value={defaultRenditionProfileId}
                >
                    {allowClearRenditionProfile && <option value={0} />}
                    {renditionProfiles.map((item) => (
                        <option value={item.id} key={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
            {!!defaultRenditionProfile && <RenditionProfileConfigurations {...defaultRenditionProfile} />}
            <AuthorizationAllowBlock permissions={[permissions.CUSTOMERS.WRITE]}>
                <div>
                    <button className="btn btn-success" type="button" onClick={save} disabled={updating}>
                        Save
                    </button>
                    {updating && (
                        <span
                            className="spinner-border spinner-border-sm ml-2 text-success"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </div>
            </AuthorizationAllowBlock>
        </div>
    );
};

export default Customer;
