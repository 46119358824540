import * as types from './../types';

const initialState = {
    messages: [],
    lastId: '1',
};
export default function placementsReducer(state = initialState, action) {
    switch (action.type) {
        case types.MESSAGE_REMOVE:
            return {
                ...state,
                messages: state.messages.filter((m) => m.id !== action.id),
            };
        case types.MESSAGE_ADD:
            if (action.message.keyId && state.messages.map((message) => message.keyId).includes(action.message.keyId)) {
                return state;
            }

            return {
                ...state,
                messages: [
                    ...state.messages,
                    {
                        type: action.message.type,
                        text: action.message.text,
                        id: state.lastId,
                        keyId: action.message.keyId,
                    },
                ],
                lastId: +state.lastId + 1 + '',
            };
        case types.MESSAGE_RESET_LIST:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
}
