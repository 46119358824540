import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import AdminTable from './../../common/AdminTable/AdminTable';
import FontService from './../../services/FontsService';
import customersService from '../../services/CustomersService';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import permissions from '../../configs/permissions';
import { maxLengthValidator, requiredValidator } from '../../helpers/validators';
import Search from '../components/Search';
import { debounce } from '../../helpers/common';

const nameValidator = [requiredValidator('Name is required'), maxLengthValidator('Max allowed 100 characters', 100)];

export default class Fonts extends Component {
    constructor(props) {
        super(props);
        this.texts = {
            pageTitle: 'Fonts',
            addText: 'Add Font',
        };
        this.state = {
            active: AuthorizationHelper.isSuperAdmin() ? 'global' : 'custom',
            customers: [],
            search: '',
            fontSearch: '',
        };
    }

    updateCustomers() {
        customersService.getCustomers().then(({ status, json }) => {
            if (status === 200) {
                this.setState({ customers: json.items });
            }
        });
    }

    componentDidMount() {
        // this.updateCustomers();

        if (this.props.location.screen === 'custom') {
            this.setState({ active: 'custom' });
        }
    }

    setActiveTab(tab) {
        this.setState({
            active: tab,
        });
    }

    deleteFont(id) {
        return FontService.deleteFontFamily(id).then((json) => {
            return json;
        });
    }

    openFiles(id, isGlobal) {
        this.setState({ openedFile: id });
    }

    onSearch = debounce(
        (query) => {
            this.setState({
                search: query,
            });
        },
        500,
        false,
    );

    handleAddFont = (global) => async (data) => {
        const { status, json } = await FontService.addFontFamily({ ...data, global });

        if (status === 200) {
            this.props.history.push({ pathname: `/fonts/${json.id}`, screen: this.state.active });
            return { status: 201, json };
        }

        return { status, json };
    };

    render() {
        const { pageTitle } = this.texts;
        const { customers, active } = this.state;

        // if (!customers.length) return <div />;

        return (
            <div className="h-100">
                <div className="form-row py-3">
                    <div className="col-auto">
                        <h4 className="display-5 m-0">{pageTitle}</h4>
                    </div>
                </div>
                <div className="card table-container">
                    <div className="card-header">
                        <ul className="nav nav-tabs card-header-tabs">
                            {AuthorizationHelper.isSuperAdmin() && (
                                <li className="nav-item">
                                    <a
                                        onClick={() => {
                                            if (active !== 'global') {
                                                this.setActiveTab('global');
                                                this.props.location.screen = 'global';
                                            }
                                        }}
                                        className={`btn nav-link ${active === 'global' ? 'active' : ''}`}
                                    >
                                        Global
                                    </a>
                                </li>
                            )}
                            <li className="nav-item">
                                <a
                                    onClick={() => active !== 'custom' && this.setActiveTab('custom')}
                                    className={`btn nav-link ${active !== 'global' ? 'active' : ''}`}
                                >
                                    Custom
                                </a>
                            </li>
                        </ul>
                        <Search label={'Find font:'} onSearch={(value) => this.onSearch(value)} />
                    </div>
                    <div className="card-body p-0">
                        {active === 'global' && this.props.location.screen !== 'custom' ? (
                            <AdminTable
                                key="global"
                                addProps={{ global: true }}
                                hideDelete
                                hideAdd={
                                    !AuthorizationHelper.hasPermissions([permissions.FONTS.WRITE]) ||
                                    active !== 'global'
                                }
                                texts={{ addText: 'Add global font' }}
                                edit={(d) => FontService.editFontFamily(d)}
                                add={this.handleAddFont(true)}
                                delete={(id) => FontService.deleteFontFamily(id)}
                                editType="none"
                                fetchData={(page) =>
                                    FontService.getFontFamiliesByType('global', this.state.search, page)
                                }
                                search={this.state.search}
                                cols={[
                                    {
                                        title: 'Name',
                                        name: 'name',
                                        ignoreInEdit: true,
                                        validator: nameValidator,
                                    },
                                    {
                                        title: 'Files',
                                        name: 'id',
                                        ignoreInAdd: true,
                                        ignoreInEdit: true,
                                        display: (val) => <NavLink to={`/fonts/${val}`}>files</NavLink>,
                                    },
                                ]}
                            />
                        ) : (
                            <AdminTable
                                key="custom"
                                addProps={{ global: false }}
                                hideAdd={
                                    !AuthorizationHelper.hasPermissions([permissions.FONTS.WRITE]) ||
                                    active === 'global'
                                }
                                hideDelete={
                                    !AuthorizationHelper.hasPermissions([permissions.FONTS.DELETE]) ||
                                    active === 'global'
                                }
                                texts={{ addText: 'Add custom font', editTitle: 'Edit font' }}
                                edit={(data, row, selectedCustomers) => {
                                    const modifiedData = { ...data, global: false, customers: selectedCustomers };
                                    return FontService.editFontFamily(modifiedData);
                                }}
                                add={this.handleAddFont(false)}
                                delete={(id) => this.deleteFont(id)}
                                editType={
                                    !AuthorizationHelper.hasPermissions([permissions.FONTS.WRITE]) ? 'none' : 'modal'
                                }
                                fetchData={(page) =>
                                    FontService.getFontFamiliesByType('custom', this.state.search, page)
                                }
                                search={this.state.search}
                                onSearch={this.onSearch}
                                cols={[
                                    {
                                        title: 'Name',
                                        name: 'name',
                                        validator: nameValidator,
                                    },
                                    // {
                                    //     title: 'Customers',
                                    //     name: 'customers',
                                    //     type: 'checkboxGroup',
                                    //     ignoreInAdd: true,
                                    //     options: this.state.customers,
                                    //     display: (val) =>
                                    //         val.map((e, i) => (
                                    //             <span key={i} className="badge badge-dark mr-1">
                                    //                 {e.name}
                                    //             </span>
                                    //         )),
                                    // },
                                    {
                                        title: 'Files',
                                        name: 'id',
                                        ignoreInAdd: true,
                                        ignoreInEdit: true,
                                        display: (val) => (
                                            <NavLink to={{ pathname: `/fonts/${val}`, screen: 'custom' }}>
                                                files
                                            </NavLink>
                                        ),
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
