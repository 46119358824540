import * as types from './../types';

export default {
    removeMessage,
    addMessage,
    resetMessagesList,
};

export function removeMessage(id) {
    return {
        type: types.MESSAGE_REMOVE,
        id,
    };
}

export function addMessage(message) {
    return {
        type: types.MESSAGE_ADD,
        message,
    };
}

export function showFailToLoadMessage(msg) {
    return (dispatch, getState) => {
        const state = getState();
        const { messages } = state.messages;
        if (!messages.find((m) => m.type === 'loadError')) {
            dispatch({
                type: types.MESSAGE_ADD,
                message: { type: 'loadError', text: msg || 'Oops!. Something went wrong. Please refresh your page.' },
            });
        }
    };
}

export function resetMessagesList() {
    return {
        type: types.MESSAGE_RESET_LIST,
    };
}
