import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './store/store';

import 'bootstrap';
import './main.scss';

import PrivateRoute from './PrivateRoute';
import Login from './pages/auth/Login';
import App from './pages/App';
import ServerConnector from './services/Serverconnector';

let store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

ServerConnector.setStore(store);

ReactDOM.render(
    <Provider key={'provider'} store={store}>
        <Router>
            <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/" component={App} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root'),
);
