import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jq from 'jquery';
import { CheckBoxGroup } from '../CheckBoxGroup';

const AddModal = ({ cols, texts, addRow }) => {
    const [values, setValues] = useState({});
    const [validators, setValidators] = useState([]);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const initialValues = {};
        const initialValidators = [];

        cols.forEach((f) => {
            if (f.type === 'checkboxGroup') {
                initialValues[f.name] = f.options.map((o) => ({ ...o, value: false }));
            } else if (f.type === 'select') {
                initialValues[f.name] = f.options.length ? f.options[0].value : '';
            } else {
                initialValues[f.name] = '';
            }

            f.validator && initialValidators.push({ name: f.name, validator: f.validator });
        });

        setValues(initialValues);
        setValidators(initialValidators);
    }, [cols]);

    const handleValueChange = (c, e) => {
        setValues({ ...values, [c]: e.target.value });
    };

    const closeModal = () => {
        setMessages([]);
        setValues({});
        setValidators([]);

        jq('#addModal').modal('hide');
    };

    const handleCheckboxChange = (c, i) => {
        const group = [...values[c]];
        group[i].value = !group[i].value;
        setValues({ ...values, [c]: group });
    };

    const handleSave = () => {
        const errors = validate();
        if (errors.length) {
            setMessages(errors.map((e) => e.msg));
            return;
        }

        addRow(values);
        closeModal();
    };

    const validate = () => {
        const result = [];
        validators.forEach((v) => {
            let err;

            if (Array.isArray(v.validator)) {
                for (let validator of v.validator) {
                    err = validator(values[v.name]);
                    if (err) {
                        break;
                    }
                }
            } else {
                err = v.validator(values[v.name]);
            }
            err && result.push(err);
        });
        return result;
    };

    return (
        <div>
            <div className="ml-auto">
                <button
                    className="btn btn-primary mt-3 fixed-bottom"
                    data-toggle="modal"
                    data-target="#addModal"
                    style={{ bottom: 10, right: 10, left: 'auto' }}
                >
                    + {texts.addText}
                </button>
                <div
                    className="modal fade"
                    id="addModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="addModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">
                                    {texts.addText}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {cols.map((f) => (
                                    <div key={f.name} className="form-group">
                                        <label>{f.title}</label>
                                        {f.type === 'textArea' && (
                                            <textarea
                                                value={values[f.name]}
                                                onChange={(e) => handleValueChange(f.name, e)}
                                                className="form-control"
                                            />
                                        )}
                                        {f.type === 'checkboxGroup' && (
                                            <CheckBoxGroup
                                                items={values[f.name]}
                                                changeItem={(i) => handleCheckboxChange(f.name, i)}
                                            />
                                        )}
                                        {f.type === 'email' && (
                                            <input
                                                type="email"
                                                value={values[f.name]}
                                                onChange={(e) => handleValueChange(f.name, e)}
                                                className="form-control"
                                            />
                                        )}
                                        {f.type === 'select' && (
                                            <select
                                                value={values[f.name]}
                                                className="form-control"
                                                onChange={(e) => handleValueChange(f.name, e)}
                                            >
                                                {f.options.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                        {(f.type === 'input' || !f.type) && (
                                            <input
                                                type="text"
                                                value={values[f.name]}
                                                onChange={(e) => handleValueChange(f.name, e)}
                                                className="form-control"
                                            />
                                        )}
                                    </div>
                                ))}

                                {(messages.length && (
                                    <div>
                                        {messages.map((message, index) => {
                                            return (
                                                <div key={index}>
                                                    <span className={'badge badge-danger'}>{message}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )) ||
                                    null}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                                    Close
                                </button>
                                <button type="button" onClick={() => handleSave()} className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
AddModal.propTypes = {
    cols: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['checkboxGroup', 'select', 'input', 'textArea', 'email']),
            name: PropTypes.string,
        }),
    ),
    texts: PropTypes.shape({
        addText: PropTypes.string,
    }),
    validator: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.any,
        }),
    ),
};

export default AddModal;
