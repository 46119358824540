import React, { useCallback, useEffect, useState } from 'react';
import jq from 'jquery';

const useDeleteModal = ({ handleDelete }) => {
    const [deleteModalData, setDeleteModalData] = useState({});

    const handleConfirm = (data) => {
        handleDelete(data);
        setDeleteModalData({});
    };

    const handleCancel = () => {
        setDeleteModalData({});
    };

    const setDeleteModal = useCallback((data) => {
        setDeleteModalData(data);
    }, []);

    useEffect(() => {
        if (deleteModalData.id) {
            jq(`#deleteModal${deleteModalData.id}`).modal('show');
        }
    }, [deleteModalData]);

    const renderDeleteModal = () => (
        <div
            className="modal fade"
            id={`deleteModal${deleteModalData.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="deleteModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="deleteModalLabel">
                            Delete
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleCancel}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">Do you want to delete {deleteModalData.name} ?</div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCancel}>
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={() => handleConfirm(deleteModalData)}
                            data-dismiss="modal"
                            className="btn btn-primary"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    return { setDeleteModal, renderDeleteModal };
};

export default useDeleteModal;
