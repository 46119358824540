import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import CustomersContainer from './../customers/CustomersContainer';
import UsersContainer from './../users/UsersContainer';
import Fonts from './../fonts/FontsContainer';
import Templates from './../templates/TemplatesContainer';
import Renditions from '../renditions/Renditions';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import permissions from '../../configs/permissions';

class Body extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="pr-3 pr-xl-4 pb-3 h-100">
                <Switch>
                    {AuthorizationHelper.hasPermissions([permissions.CUSTOMERS.READ]) && (
                        <Route path="/customers" component={CustomersContainer} />
                    )}

                    {AuthorizationHelper.isSuperAdmin() &&
                        AuthorizationHelper.hasPermissions([permissions.USERS.READ, permissions.CUSTOMERS.READ]) && (
                            <Route path="/users" component={UsersContainer} />
                        )}

                    {AuthorizationHelper.hasPermissions([permissions.FONTS.READ]) && (
                        <Route path="/fonts" component={Fonts} />
                    )}

                    {AuthorizationHelper.hasPermissions([permissions.GALLERY_TEMPLATES.READ]) && (
                        <Route path="/templates" component={Templates} />
                    )}

                    {AuthorizationHelper.isSuperAdmin() &&
                        AuthorizationHelper.hasPermissions([permissions.RENDITION_PROFILES.READ]) && (
                            <Route path="/renditions/:id" component={Renditions} />
                        )}

                    {AuthorizationHelper.isSuperAdmin() &&
                        AuthorizationHelper.hasPermissions([permissions.RENDITION_PROFILES.READ]) && (
                            <Route path="/renditions" component={Renditions} />
                        )}

                    {AuthorizationHelper.hasPermissions([permissions.CUSTOMERS.READ]) && (
                        <Redirect path="/" to={`/customers`} />
                    )}
                </Switch>
            </div>
        );
    }
}

function mapProperties(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapActions(dispatch) {
    return {};
}

export default connect(mapProperties, mapActions)(Body);
