import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Templates from './Templates';
import Export from './Export';

export default class FontsContainer extends Component {
    render() {
        return (
            <Switch>
                {/*<Route path="/templates/export" component={Export} />*/}
                <Route path="/templates/" component={Templates} />
            </Switch>
        );
    }
}
