import * as types from '../types';
import AuthService from '../../services/AuthService';
import { eraseCookie, createCookie, readCookie } from '../../helpers/cookie';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';

export default {
    login,
    getUser,
};

export function getUser() {
    return (dispatch) => {
        AuthService.getUser().then(({ json: data, status }) => {
            if (status === 200 && data) {
                AuthorizationHelper.setPermissions(data.permissions);
                AuthorizationHelper.setRoles(data.roles);

                dispatch({
                    type: types.LOGIN_SUCCESS,
                    user: data.user,
                });
            }
        });
    };
}

export function login(email, password) {
    return (dispatch) => {
        dispatch({ type: types.LOGIN_REQUEST, email });

        AuthService.login({ email, password }).then(({ json: user, status }) => {
            if (status !== 200) {
                dispatch({ type: types.LOGIN_FAIL });
            } else {
                dispatch(getUser());
            }
        });
    };
}

export function logout() {
    return (dispatch) => {
        eraseCookie('hasToken');
        eraseCookie('currentCompany');
        dispatch({ type: types.LOGOUT });
    };
}

export function logoutRequest() {
    return (dispatch) => {
        eraseCookie('hasToken');
        eraseCookie('currentCompany');
        dispatch({ type: types.LOGOUT_REQUEST });
        AuthService.logout().then(
            (s) => {
                s.status === 200 && dispatch(logout());
            },
            () => {
                alert('fail');
            },
        );
    };
}

export function setDefaults() {
    return { type: types.USER_SET_DEFAULTS };
}
