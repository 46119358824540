import ServerConnector from './Serverconnector';

class AuthService extends ServerConnector {
    constructor(path = 'api/auth') {
        super(path);
    }

    login(data) {
        // const headers = new Headers();
        const options = {
            body: JSON.stringify(data),
            method: 'POST',
            cache: 'default',
        };
        return this.send({ path: '/login', options, headers: { 'content-type': 'application/json' } });
    }

    logout() {
        const options = {
            method: 'GET',
        };
        return this.send({ path: '/logout', options });
    }
    getUser() {
        const options = {
            method: 'GET',
        };
        return this.send({ path: '/user', options });
    }
}

export default new AuthService();
