import React, { Fragment } from 'react';
import useDeleteModal from './useDeleteModal';
import useEditModal from './useEditModal';

const Col = React.memo(({ col }) => <th scope="col">{col.title}</th>);

const Row = React.memo(({ row, editType, cols, hideDelete, setEditModal, setDeleteModal }) => {
    const editTypeFormatted = typeof editType === 'function' ? editType(row) : editType;

    return (
        <tr>
            {cols
                .filter((col) => !col.hide)
                .map((c) => {
                    if (!c.display) c.display = (val) => val;
                    return <td key={c.name}>{c.display(row[c.name], row)}</td>;
                })}
            <td>
                <div style={{ display: 'inline-flex' }}>
                    {editTypeFormatted !== 'none'
                        ? editTypeFormatted === 'modal' && (
                              <button className="btn btn-warning btn-sm text-white" onClick={() => setEditModal(row)}>
                                  Edit
                              </button>
                          )
                        : null}
                    {((typeof hideDelete === 'function' ? !hideDelete(row) : !hideDelete) && (
                        <button
                            className="btn btn-sm btn-danger mx-1"
                            style={{ bottom: 10, right: 10, left: 'auto' }}
                            onClick={() => setDeleteModal(row)}
                        >
                            Delete
                        </button>
                    )) ||
                        null}
                </div>
            </td>
        </tr>
    );
});

const Table = (props) => {
    const { cols, rows, hideDelete, handleDelete, handleConfirmRow, texts, editType } = props;
    const actionsRow = {
        title: 'Actions',
        name: 'actions',
        content: {},
    };
    const tableCols = [...cols, actionsRow];

    const { renderEditModal, setEditModal } = useEditModal({
        fields: cols.filter((c) => !c.ignoreInEdit),
        modalTitle: texts.editTitle,
        handleConfirmRow,
    });
    const { renderDeleteModal, setDeleteModal } = useDeleteModal({ handleDelete });

    return (
        <Fragment>
            <table className="table table-hover table-striped m-0">
                <thead className="thead-dark">
                    <tr>
                        {tableCols
                            .filter((c) => !c.hide)
                            .map((col) => (
                                <Col col={col} key={col.name} />
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row) => {
                        return (
                            <Row
                                key={row.id}
                                row={row}
                                cols={cols}
                                editType={editType}
                                hideDelete={hideDelete}
                                setEditModal={setEditModal}
                                setDeleteModal={setDeleteModal}
                            />
                        );
                    })}
                </tbody>
            </table>
            {renderEditModal()}
            {renderDeleteModal()}
        </Fragment>
    );
};

export default Table;
