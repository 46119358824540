const accountTypes = {
    BASIC: 'BASIC',
    ADVANCED: 'ADVANCED',
};

const accountTypeOptions = [
    {
        value: accountTypes.BASIC,
        label: 'Studio Basic',
    },
    {
        value: accountTypes.ADVANCED,
        label: 'Studio Advanced',
    },
];

export default accountTypes;
export { accountTypes, accountTypeOptions };
