import React, { useState, useEffect, useCallback, useRef } from 'react';
import jq from 'jquery';
import { CheckBoxGroup } from '../CheckBoxGroup';
import Search from '../../pages/components/Search';
import { debounce } from '../../helpers/common';
import customersService from './../../services/CustomersService';

const useEditModal = ({ fields, modalTitle, handleConfirmRow }) => {
    const [editModalData, setEditModalData] = useState({});
    const [search, setSearch] = useState('');
    const [values, setValues] = useState({});
    const [validators, setValidators] = useState([]);
    const [messages, setMessages] = useState([]);
    const [updatedFields, setUpdatedFields] = useState(fields);
    const [selectedCustomers, setSelectedCustomers] = useState({});
    const isFontModal = ['Edit font'].includes(modalTitle);
    const inputRef = useRef(null);

    const transformValues = (options = null) => {
        let newValues = {};
        let newValidators = [];
        let newFields = fields;

        if (isFontModal && options) {
            const customersCheckboxGroup = {
                title: 'Customers',
                name: 'customers',
                type: 'checkboxGroup',
                ignoreInAdd: true,
                options: options,
                display: (val) =>
                    val.map((e) => (
                        <span key={e.name} className="badge badge-dark mr-1">
                            {e.name}
                        </span>
                    )),
            };

            newFields = [...newFields, customersCheckboxGroup];
        }

        newFields
            .filter((f) => !f.ignoreInEdit)
            .forEach((f) => {
                if (f.type === 'checkboxGroup') {
                    newValues = {
                        ...newValues,
                        [f.name]: f.options.map((o) => {
                            if (isFontModal) {
                                return {
                                    ...o,
                                    value: !!(
                                        editModalData[f.name] &&
                                        !!editModalData[f.name].find((r) => r.id === o.id) &&
                                        selectedCustomers[o.id] &&
                                        !!selectedCustomers[o.id].value
                                    ),
                                };
                            }
                            return {
                                ...o,
                                value: editModalData[f.name] && !!editModalData[f.name].find((r) => r.id === o.id),
                            };
                        }),
                    };
                } else {
                    newValues = { ...newValues, [f.name]: values[f.name] || editModalData[f.name] };
                }

                if (f.validator) {
                    newValidators = [...newValidators, { name: f.name, validator: f.validator }];
                }
            });

        setValues(newValues);
        setValidators(newValidators);
        setUpdatedFields(newFields);
    };

    useEffect(() => {
        if (editModalData.id) {
            transformValues(editModalData.customers);

            jq(`#editModal${editModalData.id}`).modal('show');
        }
    }, [editModalData]);

    const handleCheckboxChange = (c, i) => {
        const group = [...values[c]];
        group[i].value = !group[i].value;
        setValues({ ...values, [c]: group });

        if (group[i].value) {
            setSelectedCustomers({ ...selectedCustomers, [group[i].id.toString()]: group[i] });
        } else {
            const { [group[i].id.toString()]: omit, ...rest } = selectedCustomers;
            setSelectedCustomers(rest);
        }
    };

    const handleValueChange = (c, e) => {
        const value = e.target.value;
        setValues({ ...values, [c]: value });
    };

    const closeModal = () => {
        setValues({});
        setValidators([]);
        setMessages([]);
        setEditModalData({});
        setSearch('');

        if (inputRef.current) {
            inputRef.current.onClear();
        }
    };

    const validate = () => {
        const result = [];
        validators.forEach((v) => {
            let err;
            if (Array.isArray(v.validator)) {
                for (let validator of v.validator) {
                    err = validator(values[v.name]);
                    if (err) {
                        break;
                    }
                }
            } else {
                err = v.validator(values[v.name]);
            }

            err && result.push(err);
        });
        return result;
    };

    const handleSave = (editModalData) => {
        const errors = validate();
        if (errors.length) {
            setMessages(errors.map((e) => e.msg));
            return;
        }

        handleConfirmRow(editModalData, values, Object.values(selectedCustomers));
        closeModal();
    };

    const handleCancel = () => {
        closeModal();
    };

    const setEditModal = useCallback((data) => {
        setEditModalData(data);
        if (data.customers) {
            setSelectedCustomers(
                data.customers.reduce((acc, c) => ({ ...acc, [c.id.toString()]: { ...c, value: true } }), {}),
            );
        }
    }, []);

    const onCustomerSearch = debounce((query) => {
        setSearch(query);
    }, 500);

    useEffect(() => {
        if (search.length > 0) {
            customersService.getCustomers('', search, 0).then(({ status, json }) => {
                if (status === 200) {
                    transformValues(json.items);
                }
            });
        } else {
            transformValues(editModalData.customers);
        }
    }, [search]);

    const renderEditModal = () => (
        <div
            className="modal fade"
            id={`editModal${editModalData.id}`}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="editModalLabel">
                            {modalTitle}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleCancel}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {isFontModal && <Search label="Find customer" onSearch={onCustomerSearch} ref={inputRef} />}
                        {updatedFields.map((f) => (
                            <div key={f.name} className="form-group">
                                {isFontModal && !search && f.type === 'checkboxGroup' && (
                                    <label>Current customers</label>
                                )}
                                {isFontModal && search && f.type === 'checkboxGroup' && <label>Search results</label>}
                                {isFontModal && f.type !== 'checkboxGroup' && <label>{f.title}</label>}
                                {!isFontModal && <label>{f.title}</label>}
                                {f.type === 'textArea' && (
                                    <textarea
                                        value={values[f.name]}
                                        onChange={(e) => handleValueChange(f.name, e)}
                                        className="form-control"
                                    />
                                )}
                                {f.type === 'checkboxGroup' && (
                                    <CheckBoxGroup
                                        items={values[f.name]}
                                        changeItem={(i) => handleCheckboxChange(f.name, i)}
                                    />
                                )}
                                {f.type === 'email' && (
                                    <input
                                        type="email"
                                        value={values[f.name]}
                                        onChange={(e) => handleValueChange(f.name, e)}
                                        className="form-control"
                                    />
                                )}
                                {f.type === 'select' && (
                                    <select
                                        value={values[f.name]}
                                        className="form-control"
                                        onChange={(e) => handleValueChange(f.name, e)}
                                    >
                                        {f.options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {(f.type === 'input' || !f.type) && (
                                    <input
                                        type="text"
                                        value={values[f.name]}
                                        onChange={(e) => handleValueChange(f.name, e)}
                                        className="form-control"
                                    />
                                )}
                            </div>
                        ))}
                        {isFontModal && Object.values(selectedCustomers).length > 0 && (
                            <div>
                                <hr />
                                <p>Selected customers to be saved</p>
                                <div>
                                    {Object.values(selectedCustomers).map((c) => (
                                        <span key={c.id} className="badge badge-dark mr-1">
                                            {c.name}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                        {(messages.length && (
                            <div>
                                {messages.map((message, index) => {
                                    return (
                                        <div key={index}>
                                            <span className={'badge badge-danger'}>{message}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        )) ||
                            null}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCancel}>
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={() => handleSave(editModalData)}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    return { setEditModal, renderEditModal };
};

export default useEditModal;
