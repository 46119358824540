const permissions = {
    CUSTOMERS: {
        READ: 'customers:read',
        WRITE: 'customers:write',
        DELETE: 'customers:delete',
    },
    USERS: {
        READ: 'users:read',
        WRITE: 'users:write',
        DELETE: 'users:delete',
    },
    RENDITION_PROFILES: {
        READ: 'rendition-profiles:read',
    },
    FONTS: {
        READ: 'fonts:read',
        WRITE: 'fonts:write',
        DELETE: 'fonts:delete',
    },
    GALLERY_TEMPLATES: {
        READ: 'gallery-templates:read',
        WRITE: 'gallery-templates:write',
        DELETE: 'gallery-templates:delete',
    },
};

export default permissions;
