import React, { Component } from 'react';

import FontService from '../../services/FontsService';
import AdminTable from '../../common/AdminTable/AdminTable';
import AddFontFileModal from './AddFontFileModal';
import AuthorizationAllowBlock from '../../common/AuthorizationBlock/AuthorizationAllowBlock';
import permissions from '../../configs/permissions';
import { maxLengthValidator, requiredValidator } from '../../helpers/validators';
import { NavLink } from 'react-router-dom';

const nameValidator = [requiredValidator('Name is required'), maxLengthValidator('Max allowed 100 characters', 100)];

export default class Font extends Component {
    constructor(props) {
        super(props);
        this.state = {
            styleOptions: [],
        };
    }

    fetchFont = async () => {
        const fontId = this.props.match.params.fontId;
        const { status, json: font } = await FontService.getFontFamily(fontId);

        if (status !== 200) return { status };

        let styleOptions = [{ value: 'REGULAR', name: 'Regular' }];
        if (font.fonts.length) {
            styleOptions = [
                { value: 'BOLD', name: 'Bold' },
                { value: 'ITALIC', name: 'Italic' },
                { value: 'BOLD_ITALIC', name: 'Bold italic' },
                { value: '', name: 'Other' },
            ];
            font.fonts.forEach((f) => {
                if (f.style === 'BOLD') {
                    styleOptions = styleOptions.filter((s) => s.value !== 'BOLD');
                } else if (f.style === 'ITALIC') {
                    styleOptions = styleOptions.filter((s) => s.value !== 'ITALIC');
                } else if (f.style === 'BOLD_ITALIC') {
                    styleOptions = styleOptions.filter((s) => s.value !== 'BOLD_ITALIC');
                }
            });
        }
        this.setState({ styleOptions });

        return { json: { items: font.fonts }, status: 200 };
    };

    render() {
        const { styleOptions } = this.state;
        const fontId = this.props.match.params.fontId;

        return (
            <div className={'h-100'}>
                <div className="form-row py-3">
                    <div className="col-auto">
                        <NavLink to={{ pathname: '/fonts', screen: this.props.location.screen }}>&lsaquo; Back</NavLink>
                        <h4 className="display-5 m-0">Font</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className={`card-body p-0`}>
                            <AdminTable
                                hideAdd={false}
                                texts={{ editTitle: 'Edit font file' }}
                                editType="modal"
                                fetchData={() => this.fetchFont()}
                                edit={(d) => FontService.updateFontFile(d)}
                                cols={[
                                    {
                                        title: 'Name',
                                        name: 'name',
                                        validator: nameValidator,
                                    },
                                    {
                                        title: 'Rich text type',
                                        name: 'style', //regular, bold, italic, bold_italic, other
                                        ignoreInEdit: true,
                                    },
                                ]}
                                addComponent={AddFontFileModal}
                                addProps={{
                                    fontId,
                                    styleOptions,
                                }}
                                hideDelete
                            />

                            <AuthorizationAllowBlock permissions={[permissions.FONTS.WRITE]}>
                                <button
                                    className={'btn btn-primary mt-3 fixed-bottom'}
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#addFontFileModal"
                                    style={{ bottom: 10, right: 10, left: 'auto' }}
                                >
                                    + Add font file
                                </button>
                            </AuthorizationAllowBlock>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
