import { Redirect } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

import authorizationHelper from '../../helpers/AuthorizationHelper';

const AuthorizationAllowBlock = ({
    roles,
    permissions,
    redirectTo,
    children,
    checkAllPermissions,
    renderForbidden,
}) => {
    if (roles.length && !roles.some((role) => authorizationHelper.hasRole(role))) {
        return null;
    }

    if (
        checkAllPermissions
            ? authorizationHelper.hasPermissions(permissions)
            : authorizationHelper.hasOneOfThePermissions(permissions)
    ) {
        return children;
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    if (renderForbidden) {
        return renderForbidden;
    }

    return null;
};

AuthorizationAllowBlock.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    redirectTo: PropTypes.string,
    children: PropTypes.node,
    checkAllPermissions: PropTypes.bool,
    renderForbidden: PropTypes.node,
};

AuthorizationAllowBlock.defaultProps = {
    roles: [],
};

export default AuthorizationAllowBlock;
