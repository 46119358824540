import ServerConnector from './Serverconnector';

class TemplatesService extends ServerConnector {
    constructor(path = 'api/templates') {
        super(path);
    }

    getTemplates() {
        const options = {
            method: 'GET',
        };
        return this.send({ path: '/', options });
    }
    getTemplateByType(type) {
        const options = {
            method: 'GET',
        };
        return this.send({ path: `/byType/${type}`, options });
    }
    deleteTemplate(id) {
        const options = {
            method: 'DELETE',
        };
        return this.send({ path: `/${id}`, options });
    }
    editTemplate(data) {
        const options = {
            method: 'PUT',
            body: JSON.stringify(data),
        };
        return this.send({ path: `/${data.id}`, options });
    }
    addTemplate(data) {
        const options = {
            method: 'PUT',
            body: JSON.stringify(data),
        };

        return this.send({ path: `/`, options });
    }
    getTemplate(id) {
        const options = {
            method: 'GET',
        };
        return this.send({ path: `/${id}`, options });
    }
}

export default new TemplatesService();
