import React, { useState } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import UsersStandalone from './UsersStandalone';
import UsersBynder from './UsersBynder';
import { debounce } from '../../helpers/common';
import Search from '../components/Search';

const UsersContainer = ({ match }) => {
    const { path, url } = match;
    const [search, setSearch] = useState('');
    const isActive = (param) => window.location.pathname === `${path}/${param}`;

    const onSearch = debounce(
        (query) => {
            setSearch(query);
        },
        500,
        false,
    );

    return (
        <div className="h-100">
            <div className="form-row py-3">
                <div className="col-auto">
                    <h4 className="display-5 m-0">Users</h4>
                </div>
            </div>

            <div className="card table-container">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <NavLink
                                className={'btn nav-link'}
                                activeClassName={'active'}
                                to={`${path}/standalone`}
                                onClick={(e) => {
                                    if (isActive('standalone')) {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                Standalone
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={'btn nav-link'}
                                activeClassName={'active'}
                                to={`${path}/bynder`}
                                onClick={(e) => {
                                    if (isActive('bynder')) {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                Bynder
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <Search label={'Find user:'} onSearch={onSearch} />
                <div className={'card-body p-0'}>
                    <Switch>
                        <Route
                            path={`${path}/standalone`}
                            render={(props) => <UsersStandalone search={search} {...props} />}
                        />
                        <Route path={`${path}/bynder`} component={UsersBynder} />

                        <Redirect path={`${path}`} to={`${url}/standalone`} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default UsersContainer;
