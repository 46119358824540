import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeMessage } from '../../store/messages/messages.actions';
import Message from './Message';

class Messages extends Component {
    render() {
        const message = this.props.messages[0];

        return (
            <div className="fixed-top text-center" style={{ zIndex: 1060, width: 500, left: 'calc(50% - 250px)' }}>
                {message && <Message key={message.id} {...message} remove={(id) => this.props.removeItem(id)} />}
            </div>
        );
    }
}

function mapActions(dispatch) {
    return {
        removeItem: (id) => {
            dispatch(removeMessage(id));
        },
    };
}

function mapProperties(state, ownProps) {
    const { messages } = state.messages;
    return {
        messages,
    };
}

export default connect(mapProperties, mapActions)(Messages);
