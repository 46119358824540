import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table from '../Table/Table';
import AddModal from './AddModal';
import Pagination from '../Pagination';

const AdminTable = ({
    fetchData,
    edit,
    add,
    delete: deleteRow,
    texts,
    hideAdd,
    addComponent,
    addProps,
    hideDelete,
    cols,
    editType,
    bynderCustomer,
    search,
}) => {
    const Add = addComponent ? addComponent : AddModal;

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        getAndSetData();
    }, [page, search]);

    const getAndSetData = () => {
        setIsLoading(true);
        fetchData(page).then(({ json, status }) => {
            let hasMore = false;

            if (status === 200) {
                if (json.items) {
                    if (json.items.length > 0) {
                        const updatedRows = json.items;
                        if (json.hasOwnProperty('hasMore')) {
                            hasMore = json.hasMore;
                        } else {
                            hasMore = updatedRows.length < json.totalItems;
                        }
                        setRows(updatedRows);
                        setHasMore(hasMore);
                        setTotalItems(json.totalItems);
                        setIsLoading(false);
                    } else {
                        setRows([]);
                        setHasMore(false);
                        setTotalItems(0);
                        setIsLoading(false);
                    }
                } else {
                    setHasMore(false);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                setHasMore(false);
            }
        });
    };

    const setRow = (updatedRow) => {
        const updatedRows = [...rows];
        const index = updatedRows.findIndex((currentRow) => currentRow.id === updatedRow.id);
        updatedRows[index] = updatedRow;
        setRows(updatedRows);
    };

    const confirmEditRow = (row, values, selectedCustomers) => {
        const data = { ...values, id: row.id };
        edit(data, row, selectedCustomers).then(({ status, json }) => {
            if (status === 200) {
                setRow(json);
            } else if (status === 409) {
                alert('already exists');
            }
        });
    };

    const addRow = (v) => {
        add(v).then(({ status, json }) => {
            if (status === 200) {
                getAndSetData();
            } else if (status === 409) {
                alert('already exists');
            }
        });
    };

    const addRowCb = () => {
        getAndSetData();
    };

    const handleDeleteRow = (row) => {
        const id = row.id;
        deleteRow(id).then(({ status, json }) => {
            if (status === 200) {
                getAndSetData();
            }
        });
    };

    const handleChangePage = (newPageNum) => {
        setPage(newPageNum);
    };

    const renderPreloader = () => (
        <div class="h-100 centered">
            <div class="spinner-border text-primary" role="status" />
        </div>
    );

    const renderEmptyState = () => <div class="h-100 centered empty-state">No data</div>;

    return (
        <React.Fragment>
            <div className="table-responsive card">
                {!hideAdd && (
                    <Add
                        addProps={addProps}
                        texts={texts}
                        cols={cols.filter((c) => !c.ignoreInAdd)}
                        addRowCb={addRowCb}
                        addRow={addRow}
                    />
                )}
                {isLoading && renderPreloader()}
                {!isLoading && !rows.length && renderEmptyState()}
                {!isLoading && rows.length > 0 && (
                    <Table
                        editType={editType}
                        cols={cols}
                        rows={rows}
                        texts={texts}
                        bynderCustomer={bynderCustomer}
                        handleDelete={handleDeleteRow}
                        handleConfirmRow={confirmEditRow}
                        hideDelete={hideDelete}
                    />
                )}
            </div>
            <Pagination
                changePageCb={handleChangePage}
                page={page}
                isLoading={isLoading}
                hasMore={hasMore}
                totalItems={totalItems}
            />
        </React.Fragment>
    );
};

AdminTable.propTypes = {
    fetchData: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    texts: PropTypes.object.isRequired,
    hideAdd: PropTypes.bool,
    addComponent: PropTypes.func,
    addProps: PropTypes.object,
    hideDelete: PropTypes.bool,
    cols: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
        }),
    ),
    editType: PropTypes.string,
    bynderCustomer: PropTypes.any,
};

export default AdminTable;
